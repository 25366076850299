import React, { useEffect, useRef, useState } from "react";
import BasicInfo from "../../components/account/basicInfo";
import ChangePassword from "../../components/account/changePassword";
import { Settings } from "../../components/account/settings";
import SocialMedia from "../../components/account/socialMedia";
import TravelProfile from "../../components/account/travelProfile";

import SelectOrganizationInfo from "../../components/organization/SelectOrganizationInfo";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Pencil } from "mdi-material-ui";
import { randomImage } from "../../components/common/randomImage";
import { getBasicInfo } from "../../utils";
import * as actions from "./redux/actions";
import api, { apiService } from "../../common/api";
import { getOrganizationList } from "../organization/redux/getOrganizationList";

const AccountComponent = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    ActiveArea: props.ActiveArea,
    dataProps: {},
    workspaces: [],
    requestData: {},
    exportMailModal: false,
    CurrentUser: {},
  });

  const fileRef = useRef();
  const imageRef = useRef();
  const imageBlob = useRef();

  const workspaces = useSelector(
    (state) => state.organizations.organizationsList
  );
  const changingPassword = useSelector(
    (state) => state.account.changingPassword
  );
  const loading = useSelector((state) => state.account.loading);

  useEffect(() => {
    (async () => {
      const basicInfo = getBasicInfo();
      const currentUserGUID = getBasicInfo().GUID;
      setState((prev) => ({ ...prev, CurrentUser: basicInfo }));
      dispatch(actions.getStripeCustomerID(basicInfo.GUID));

      const request = await api.get("/user/getaccount?guid=" + currentUserGUID);

      const result = await apiService.get(
        `Organizations/GetOrganizations?AccountGUID=${getBasicInfo().GUID}&source=web`
      );

      setState((prev) => ({
        ...prev,
        dataProps: request.data.Response,
        workspaces: result.data.response.map((data) => {
          return data;
        }),
      }));
    })();
  }, [workspaces]);

  const toggleExportMail = (event) => {
    selectOption("support");
    setState((prev) => {
      return {
        ...prev,
        exportMailModal: !state.exportMailModal,
      };
    });
  };

  const setRequesData = (data) => {
    setState((prev) => {
      return {
        ...prev,
        requestData: data,
      };
    });
  };

  const setDataProps = (data) => {
    setState((prev) => {
      return {
        ...prev,
        dataProps: data,
      };
    });
  };

  const selectOption = (opt) => {
    setState((prev) => {
      return {
        ...prev,
        ActiveArea: opt,
      };
    });
  };

  const handleClick = () => {
    fileRef.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setState((prev) => {
          return { ...prev, src: reader.result };
        })
      );
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  let { exportMailModal } = state;

  let fullName = state.CurrentUser.Name + " " + state.CurrentUser.LastName;
  let initials =
    (state.CurrentUser.Name && state.CurrentUser.Name.charAt(0).toUpperCase()) +
    (state.CurrentUser.LastName && state.CurrentUser.LastName.charAt(0));
  let img = state.CurrentUser.Picture
    ? `https://app.plnify.com/photos/${state.CurrentUser.Picture}?w=75&h=75&mode=crop&autorotate=true`
    : randomImage(initials);

  return (
    <div
      className="AccountContainer card shadow-sm overflow-hidden"
      style={{ height: "480px" }}
    >
      <div className="card-body row p-0">
        <div className="col-lg-2 col-4 px-1 py-2">
          <div className="row">
            <div className="col text-center position-relative">
              <div
                className="editLogo"
                style={{ left: 0 }}
                onClick={handleClick}
              >
                <Pencil />
                <input
                  ref={fileRef}
                  value={state.src}
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  className="d-none"
                  onChange={handleChange}
                />
              </div>

              <img
                src={img}
                className="img-fluid mx-auto rounded my-4 d-inline-block"
                alt={fullName}
              />
            </div>

            <button
              className={
                state.ActiveArea === "basicInfo"
                  ? "btn btn-block active"
                  : "btn btn-block"
              }
              onClick={() => selectOption("basicInfo")}
            >
              <FormattedMessage
                id="basicInformation"
                defaultMessage="Basic Information"
              />
            </button>
            <button
              className={
                state.ActiveArea === "selectWorkSpace"
                  ? "btn btn-block active"
                  : "btn btn-block"
              }
              onClick={() => selectOption("selectWorkSpace")}
            >
              <FormattedMessage id="workspaces" defaultMessage="Workspaces" />
            </button>
            <button
              className={
                state.ActiveArea === "travelProfile"
                  ? "btn btn-block active"
                  : "btn btn-block"
              }
              onClick={() => selectOption("travelProfile")}
            >
              <FormattedMessage
                id="travelProfile"
                defaultMessage="Travel Profile"
              />
            </button>
            <button
              className={
                state.ActiveArea === "socialMedia"
                  ? "btn btn-block active"
                  : "btn btn-block"
              }
              onClick={() => selectOption("socialMedia")}
            >
              <FormattedMessage
                id="socialMedia"
                defaultMessage="Social Media"
              />
            </button>

            <button
              className={
                state.ActiveArea === "settings"
                  ? "btn btn-block active"
                  : "btn btn-block"
              }
              onClick={() => selectOption("settings")}
            >
              <FormattedMessage id="preferences" defaultMessage="Preferences" />
            </button>

            <div className="col-12 my-0 p-0">
              <hr />
            </div>
            <button
              className={
                state.ActiveArea === "changePassword"
                  ? "btn btn-block active"
                  : "btn btn-block"
              }
              onClick={() => selectOption("changePassword")}
            >
              <FormattedMessage
                id="changePassword"
                defaultMessage="Change Password"
              />
            </button>
          </div>
        </div>
        <div className="col-lg-10 col-8 content px-2 bg-light">
          {state.ActiveArea === "selectWorkSpace" ? (
            <div className="row-auto h-100 overflow-auto">
              {state?.workspaces
                ?.filter((x) => x.Organization.Deleted === null)
                ?.sort((a, b) =>
                  a.Organization.Name.localeCompare(b.Organization.Name)
                )
                ?.map((organization) => (
                  <SelectOrganizationInfo
                    key={organization.GUID}
                    data={organization}
                  />
                ))}
            </div>
          ) : null}

          {state.ActiveArea === "basicInfo" ? (
            <BasicInfo
              loading={loading}
              editBasicInfo={state.dataProps}
              requesData={state.requestData}
              setRequesData={setRequesData}
            />
          ) : null}
          {state.ActiveArea === "socialMedia" ? (
            <SocialMedia
              updateSocialMedia={actions.updateSocialMedia}
              consultSocialMedia={state.dataProps}
              setDataProps={setDataProps}
            />
          ) : null}
          {state.ActiveArea === "changePassword" ? (
            <ChangePassword
              changePassword={actions.changePassword}
              changingPassword={changingPassword}
              dataPassword={state.dataProps}
            />
          ) : null}
          {state.ActiveArea === "travelProfile" ? (
            <TravelProfile
              dataProps={state.dataProps}
              setDataProps={setDataProps}
            />
          ) : null}
          {state.ActiveArea === "settings" ? <Settings /> : null}
        </div>
      </div>
    </div>
  );
};

export default AccountComponent;
