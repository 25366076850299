import api, { apiService } from "../../../common/api";
import { setOrganization } from "../../../utils";
import uuid from "uuid";
import db from "../../../common/db";

import * as constant from "./constants";

const urlOrganization = `/Organizations/AddEditWithoutImage`;
const imageUrl = "/Organizations/UploadImage";

export function createOrganization(organization, image, callBack) {
  return async (dispatch) => {
    dispatch({
      type: constant.CREATING_ORGANIZATION,
    });
    try {
      if (image) {
        let fileName = image.name;
        const fileExtension = image.type.split("/")[1];
        if (!fileName.includes(`.${fileExtension}`)) {
          fileName += `.${fileExtension}`;
        }
        let bodyData = new FormData();
        bodyData.append("file", image, fileName);
        const response = await apiService.post(`/File/Upload/`, bodyData);
        organization.Logo = response.data.Response;
      }

      const request = await creatingOrganization(urlOrganization, organization);
      if (request.statusCode === 200) {
        setOrganization(organization);
      }
      await db.table("organizations").put(organization);

      dispatch({
        type: constant.CREATED_ORGANIZATION,
      });
      dispatch({
        type: constant.SET_CURRENT_ORGANIZATION,
        data: organization,
      });
      callBack();
    } catch (error) {
      console.error(error);
      dispatch({
        type: constant.CREATING_ORGANIZATION_ERROR,
      });
    }
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case constant.CREATING_ORGANIZATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.CREATED_ORGANIZATION: {
      return {
        ...state,
        loading: false,
      };
    }
    case constant.CREATING_ORGANIZATION_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}

function creatingOrganization(url, organization) {
  return apiService.post(url, {
    Name: organization.Name,
    GUID: organization.GUID,
    CreatorGUID: organization.CreatorGUID,
    Active: organization.active,
    Logo: organization.Logo,
  });
}
