import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import CreateOrganization from "./CreateOrganization";
import { getBasicInfo } from "../../../utils";
import history from "../../../components/common/history";
import {
  ModalComponent,
  ToastNotificationSuccess,
} from "../../../components/common";
import { bindActionCreators } from "redux";
import LogoCrop from "../../../components/organization/LogoCrop";
import uuid from "uuid";

import * as actions from "../redux/actions";

export class CreateOrganizationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      mime: "image/png",
      organizationName: "",
      organizationNameError: "",
      errorMessage: "",
      cropModal: true,
    };
    this.fileRef = createRef();
    this.imageRef = createRef();
    this.imageBlob = createRef();
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  closeCropModal = () => {
    this.setState({
      cropModal: true,
      src: null,
    });
  };

  onCloseModal = () => {
    this.setState({
      loaded: false,
      croppedImageUrl: null,
      errorMessage: "",
    });
  };
  toggleCropModal = () => {
    this.setState({
      cropModal: !this.state.cropModal,
    });
  };
  handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  handleClick = () => {
    this.fileRef.current.click();
  };

  handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        this.imageBlob = blob;
        blob.name = uuid.v4();
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  successToCalendar = () => {
    ToastNotificationSuccess(1, "Organization successfully created");
    history.push("/dashboard/:id");
  };

  successCreated = () => {
    ToastNotificationSuccess(1, "Organization successfully created");
  };

  createOrganization = (Name) => {
    const user = getBasicInfo();
    const organization = {
      Name,
      GUID: uuid.v4(),
      CreatorGUID: user.GUID,
      Active: true,
    };
    const image = this.imageBlob || this.imageRef;
    if (this.props.isBackButton) {
      this.props.actions.createOrganization(organization, image, () => {
        this.successCreated();
        this.props.toggleBack();
        this.props.goToIndex();
      });
      return;
    } else {
      this.props.actions.createOrganization(organization, image, () =>
        this.successToCalendar()
      );
      return;
    }
  };

  render() {
    const { organization, loading, toggleBack, toggleSelect } = this.props;
    const { croppedImageUrl, src } = this.state;
    return (
      <>
        {src && (
          <ModalComponent
            modal={this.state.cropModal}
            toggle={() => this.toggleCropModal()}
            childeren={
              <LogoCrop
                onCreation
                croppedImageUrl={croppedImageUrl}
                src={src}
                onComplete={this.onCropComplete}
                onChangeCrop={this.onCropChange}
                onImageLoaded={this.onImageLoaded}
                toggle={() => this.closeCropModal()}
                sendImage={this.sendImage}
                loading={loading}
                close={this.closeCropModal}
              />
            }
          />
        )}
        <CreateOrganization
          {...this.state}
          isBackButton={this.props.isBackButton}
          toggleBack={toggleBack}
          history={history}
          createOrganization={this.createOrganization}
          loading={organization.loading}
          onSelectFile={this.onSelectFile}
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          onChange={this.onCropChange}
          onToggleCropper={this.onToggleModal}
          onCloseModal={this.onCloseModal}
          handleChange={this.handleChange}
          toggleSelect={toggleSelect}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: state.organizations,
  loading: state.organizations.loading,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrganizationContainer);
