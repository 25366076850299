import React, { useEffect, useState } from "react";
import { IMAGES, ROUTES } from "../../constant";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Navigate } from "react-big-calendar";
import { useHistory, useLocation } from "react-router-dom";
import { apiService } from "../../common/api";
import Swal from "sweetalert2";
import { getBasicInfo, logoutUser } from "../../utils";
import { useSelector } from "react-redux";

const initialOptions = {
  "client-id": "test",
  currency: "USD",
  intent: "capture",
};

const steps = {
  subscription: [
    {
      name: "Business",
      icon: "fa fa-briefcase",
      color: "text-success",
    },
    {
      name: "Personal",
      icon: "fa fa-user",
      color: "text-primary",
    },
    {
      name: "Companies",
      icon: "fa fa-users",
      color: "text-warning",
    },
  ],
  modules: [
    {
      name: "Module 1",
      icon: "fa fa-cogs",
      color: "text-success",
      price: 9.99,
    },
    {
      name: "Module 2",
      icon: "fa fa-check-square",
      color: "text-primary",
      price: 25.99,
    },
    {
      name: "Module 3",
      icon: "fa fa-user",
      color: "text-warning",
      price: 49.99,
    },
  ],
  plan: [
    {
      name: "Monthly",
      icon: "fa fa-calendar",
      color: "text-success",
      multiply: 1,
    },
    {
      name: "Quarterly",
      icon: "fa fa-calendar-alt",
      color: "text-primary",
      multiply: 3,
    },
    {
      name: "Annual",
      icon: "fa fa-calendar-check",
      color: "text-warning",
      multiply: 12,
    },
  ],
};

const PlanSelectionComponent = () => {
  const currentDomain = window.location.origin;
  const [suscriptions, setSuscriptions] = useState([]);
  const location = useLocation();
  const userId = location.search.split("=")[1];
  const Navigate = useHistory();
  const currentUser = getBasicInfo();
  const baseImageUrl = "https://service.plnify.com/files/";
  const organization = useSelector(
    (state) => state.organizations.currentOrganization
  );

  const [formState, setFormState] = React.useState({
    subscription: {},
    modules: ["Module 1"],
    admins: 0,
    months: 1,
    plan: 0,
  });

  const handleSubscriptionSelect = (value) => {
    setFormState({ ...formState, subscription: value });
  };

  const handlePlanSelect = (value) => {
    setFormState({ ...formState, plan: value });
  };

  const handleModuleSelect = (value) => {
    const selectedPlans = [...formState.modules];
    if (selectedPlans.includes(value)) {
      selectedPlans.splice(selectedPlans.indexOf(value), 1);
    } else {
      selectedPlans.push(value);
    }
    setFormState({ ...formState, modules: selectedPlans });
  };

  const handleAdminSelect = (value) => {
    setFormState({ ...formState, admins: value });
  };

  const fetchSuscriptions = async () => {
    try {
      const response = await apiService.get(
        `/Subscriptions/Types?Id=${userId}`
      );
      setSuscriptions(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSuscriptions();
  }, []);

  useEffect(() => {
    if (suscriptions.length > 0) {
      handleSubscriptionSelect(suscriptions[0]);
    }
  }, [suscriptions]);

  const subTotals =
    (formState.subscription.Cost +
      formState.admins * formState.subscription.PricePerAdditionaAdmin) *
    formState.months;
  const totals = subTotals?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: subTotals.toString(),
          },
        },
      ],
    });
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      Swal.fire({
        icon: "success",
        title: "Payment Successful",
        text: `Transaction completed by ${name}`,
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      });
    });
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div
        className="d-flex justify-content-center align-items-center px-10 flex-column"
        style={{
          maxWidth: "1440px",
          margin: "auto",
          height: "100vh",
        }}
      >
        <div className="d-flex justify-content-center align-items-center p-4 w-100 border-bottom position-relative">
          <div className="d-flex align-items-center   w-25">
            <button
              className="btn btn-primary"
              onClick={() => Navigate.goBack()}
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Back
            </button>
            <div
              className="d-flex justify-content-center align-items-center"
              onClick={() => Navigate.push(ROUTES.SELECT_ORGANIZATION)}
              style={{
                cursor: "pointer",
              }}
            >
              <img
                className="mx-2 object-fit-cover rounded-lg"
                width={50}
                height={50}
                src={baseImageUrl + organization.Logo}
                alt="organization image"
              />
              <span className="font-weight-bold fa-1x">
                {organization.Name}
              </span>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center flex-column w-50">
            <a href={currentDomain}>
              <img src={IMAGES.LOGO_COLOR} alt="plnify logo" />
            </a>
          </div>

          <div className="d-flex justify-content-center align-items-center flex-column w-25">
            {/* left empty space */}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center p-3 border-bottom mx-auto w-100">
          <div>
            <h5 className="mb-1 d-flex align-items-center">
              {`${currentUser.Name} ${currentUser.LastName} `}{" "}
              <button
                className="btn btn-primary ml-2"
                onClick={() => {
                  logoutUser();
                }}
              >
                Logout
              </button>
            </h5>
            <a
              href="mailto:info@valegos.com"
              className="text-muted small mt-0 p-0"
            >
              {currentUser.Email}
            </a>
          </div>
        </div>

        <div
          className="d-flex p-4 w-100 flex-column flex-md-row"
          style={{
            height: "calc(100vh - 100px)",
            overflowY: "auto",
          }}
        >
          <div className="d-flex px-4 flex-column w-100">
            <div className="d-flex justify-content-center align-items-center flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="px-4 py-3 w-100 border-bottom-2  border">
                1. Select subscription
              </h4>
              <div className="d-flex justify-content-center align-items-center p-4 w-100">
                <div
                  className="d-flex justify-content-center align-items-center w-100 bg-white p-2 flex-column"
                  style={{ gap: "10px" }}
                >
                  {suscriptions.map((subscription) => (
                    <div
                      key={subscription.Name + subscription.GUID}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSubscriptionSelect(subscription)}
                      className={`w-100 p-2  px-4 d-flex justify-content-between align-items-center
                        rounded-lg
                        ${
                          formState.subscription.GUID === subscription.GUID
                            ? "bg-primary text-white"
                            : "bg-white border-1 border"
                        }
                        `}
                    >
                      <span>{subscription.Name}</span>
                      <span>${subscription.Cost}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="px-4 py-3 w-100 border-bottom-2  border">
                2. Additional admins
              </h4>
              <div className="d-flex justify-content-center align-items-center w-100 p-4">
                <div className="d-flex justify-content-center align-items-center w-100 bg-white p-2 flex-wrap">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter amount of additional admins"
                    min={0}
                    value={formState.admins}
                    onChange={(e) =>
                      setFormState({ ...formState, admins: e.target.value })
                    }
                  />

                  {/* {steps.modules.map((module) => (
                    <div
                      key={module.name}
                      style={{ cursor: "pointer", width: "150px" }}
                      onClick={() => handleModuleSelect(module.name)}
                      className={`p-4 d-flex flex-column justify-content-center align-items-center
                        rounded-lg
                        ${
                          formState.modules.includes(module.name)
                            ? "bg-primary text-white"
                            : "bg-white"
                        }
                        `}
                    >
                      <i
                        className={`${module.icon} ${module.color} fa-3x mb-2`}
                      />
                      <span>{module.name}</span>
                      <span>${module.price}</span>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="px-4 py-3 w-100 border-bottom-2 border">
                3. Month Duration
              </h4>
              <div className="d-flex justify-content-center align-items-center p-4 w-100">
                <div className="d-flex justify-content-center align-items-center w-100 bg-white p-2 flex-wrap">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter duration in month"
                    min={1}
                    value={formState.months}
                    onChange={(e) =>
                      setFormState({ ...formState, months: e.target.value })
                    }
                  />
                  {/* {steps.plan.map((plan) => (
                    <div
                      key={plan.name}
                      style={{
                        cursor: "pointer",
                        width: "150px",
                      }}
                      onClick={() => handlePlanSelect(plan.name)}
                      className={`p-4 d-flex flex-column justify-content-center align-items-center
                        rounded-lg
                        ${
                          formState.plan === plan.name
                            ? "bg-primary text-white"
                            : "bg-white"
                        }
                        `}
                    >
                      <i className={`${plan.icon} ${plan.color} fa-3x mb-2`} />
                      <span>{plan.name}</span>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="px-4 py-3 w-100 border-bottom-2  border">
                4. Summary
              </h4>
              <div className="d-flex justify-content-center p-4 w-100 flex-column pt-0">
                <div className="d-flex w-100 bg-white p-2 align-items-center">
                  <span className="text-primary">Subscription:</span>
                  <span className="mx-1">
                    {formState.subscription?.Name} x{" "}
                    {formState.subscription?.Cost?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </div>
                <div className="d-flex align-items-center w-100 bg-white p-2">
                  <span className="text-primary">Additional Admins:</span>
                  <span className="mx-1">
                    {formState?.admins} x{" "}
                    {formState.subscription?.PricePerAdditionaAdmin?.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                      }
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center w-100 bg-white p-2">
                  <span className="text-primary">Period:</span>
                  <span className="mx-1">
                    {`${formState.months} ${
                      formState.months == 1 ? "Month" : "Months"
                    }`}
                  </span>
                </div>
                <div className="d-flex align-items-center w-100 bg-white p-2">
                  <span className="text-primary">Total :</span>
                  <span className="mx-1">{totals}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex px-4 flex-column w-100 h-100">
            <div className="d-flex flex-column rounded-lg shadow border my-2 w-100 bg-white">
              <h4 className="px-4 py-3 w-100 border-bottom-2  border">
                5. Payment
              </h4>
              <div className="d-flex justify-content-center align-items-center w-100 fa-2x p-4">
                <span className="text-primary">Total :</span>
                <span className="mx-1">{totals}</span>
              </div>

              <div className="d-flex justify-content-center align-items-center px-4 pt-2 pb-5 w-100">
                <PayPalButtons
                  className="w-100"
                  style={{ layout: "vertical" }}
                  createOrder={(data, actions) => onCreateOrder(data, actions)}
                  onApprove={(data, actions) => onApproveOrder(data, actions)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default PlanSelectionComponent;
